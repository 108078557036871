<template>
  <div>
    <div class="reserved-area-top-box-wrapper">
      <div class="container">
        <div class="reserved-area-top-box d-flex justify-content-center align-items-center flex-column">
          <p class="reserved-area-top-title">Olá {{ this.$root.userInfo.name }},</p>
          <p class="reserved-area-top-subtitle">em que podemos ajudar?</p>
        </div>
      </div>
    </div>
    <div class="reserved-area-page">
      <div class="container">
        <div class="d-xl-flex">
          <div class="reserved-area-page-column">
            <reservedAreaMenu/>
          </div>
          <div class="reserved-area-page-column">
              <p class="section-title faqs-title d-none d-xl-block">Faqs</p>
              <div class="reserved-area-faqs">
                <div class="reserved-area-faqs-item" v-for="(item,index) in areaItems" :key="index">
                  <button type="button" class="reserved-area-faqs-item-btn d-flex justify-content-between align-items-center" @click="toggleFaqs">
                    <p>{{ item.title }}</p>
                    <div class="reserved-area-faqs-item-btn-icon d-flex justify-content-center align-items-center">
                      <div class="closed-icon">
                        <chevronDown/>
                      </div>
                      <div class="active-icon">
                        <closeIcon/>
                      </div>
                    </div>
                  </button>
                  <div class="reserved-area-faqs-item-body">
                    <p>{{ item.description }}</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import chevronDown from "@/components/svg/chevron-down.vue";
import closeIcon from "@/components/svg/close.vue";
import axios from "axios";

export default {
  name: "faqs",
  data() {
    return {
      lang: this.$root.siteLang,
      areaItems: [],
    }
  },
  components: {
    reservedAreaMenu,
    chevronDown,
    closeIcon
  },
  metaInfo () {
    return {
      title: this.$root.translations.definicoes,
    }
  },
  methods: {
    toggleFaqs (e) {
      let clickedItem = e.currentTarget.closest('.reserved-area-faqs-item');
      let items = Array.from(document.querySelectorAll('.reserved-area-faqs-item'));

      if ( clickedItem.classList.contains('active') ) {
        clickedItem.classList.remove('active');
      } else {
        items.forEach(node => {
          node.classList.remove('active');
        });

        clickedItem.classList.add('active');
      }
    }
  },
  created() {

    this.$root.pageTitle = this.$root.translations.definicoes;

    axios
        .get(process.env.VUE_APP_API_URL + 'areas/15/items?type=cp&with_category_title=0&lang=' + this.lang)
        .then((response) => {
          this.areaItems = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },

}
</script>